import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';

import SelectFighters from './SelectFighters';
import SelectArena from "./SelectArena";
import Fight from "./Fight";
import Result from "./Result";
import Intro from "./Intro";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-185782634-4";
ReactGA.initialize(TRACKING_ID);


const Main = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route exact={true} path='/' element={<Intro/>} />
            <Route exact={true} path='/fight' element={<Fight/>} />
            <Route exact={true} path='/result' element={<Result/>} />

            <Route exact={true} path='/fighters' element={<SelectFighters/>} />
            <Route exact={true} path='/arenas' element={<SelectArena/>} />
        </Routes>
    );
}

export default Main;