import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useNavigate} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {Sound} from "./sound2";
import VolumeButton from "./VolumeButton";
import {useEffect} from "react";
import ReactGA from 'react-ga';

function Intro() {

    const navigate = useNavigate();

    function toFighters() {

        Sound.startGame().play(null, function () {
            navigate("/fighters");
            isMobile && window.scrollTo(0, 1);
        })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">

            <main className="App-body">
                <div className="fighters">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="selection-header">
                                GAME OF STONKS
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <div>
                                    You have been chosen to represent the retail investors in Game of Stonks. The ordinary people need your help to profit in the Stock Market.
                                </div>
                                <div>
                                    Be warned. Although your souls are protected against market crash; your lives are not.
                                </div>
                                <div>
                                    There is no time to waste the fights are waiting for you.
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} cl>
                            <Grid item xs={12} md={12}>
                                <div className="fighter-weapons">
                                    <VolumeButton/>
                                    <a href="#" onClick={e => toFighters()} className="fighter-weapon">
                                        START >
                                    </a>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div>&nbsp;</div>
                                <div>
                                    All the fights are based on real datasets and strategies backtested. Not an investment advice.
                                </div>
                                <div>&nbsp;</div>
                                <div>
                                    The game is more fun with sound on.
                                </div>
                            </Grid>
                        </Grid>


                    </Box>
                </div>
                <div>
                    <audio controls={false} preload="auto">
                        <source src={Sound.startGame().path} type="audio/mpeg"/>
                        <source src={Sound.menu().path} type="audio/mpeg"/>
                        <source src={Sound.roundOne().path} type="audio/mpeg"/>

                        <source src={Sound.versus("wsb").path} type="audio/mpeg"/>
                        <source src={Sound.versus("cramer").path} type="audio/mpeg"/>
                        <source src={Sound.versus("buffett").path} type="audio/mpeg"/>
                        <source src={Sound.versus("wood").path} type="audio/mpeg"/>
                    </audio>
                </div>
            </main>
        </div>
    );
}

export default Intro;
