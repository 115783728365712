import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {fighters} from "./data";
import Fighter from "./Fighter";
import {ShareSocial} from 'react-share-social'
import Airtable from "airtable";
import {useNavigate} from "react-router-dom";
import {Sound} from "./sound2";
import {isMobile} from "react-device-detect";
import VolumeButton from "./VolumeButton";
import ReactGA from "react-ga";

function Result() {
    const navigate = useNavigate();

    const shareUrl = "https://game.breakingequity.com/";

    const [searchParams, setSearchParams] = useSearchParams();

    const year = searchParams.get("y");
    const f = searchParams.get("f");
    const vs = searchParams.get("vs");
    const fighter = fighters.filter((fighter, index) => fighter.id === f)[0];
    const vsFighter = fighters.filter((fighter, index) => fighter.id === vs)[0];
    const winner = searchParams.get("w");


    //we save emails into airtable
    const airtable = new Airtable({apiKey: 'keymTxKAFSuc4tdsr'}).base("appEsP9gPBrKNwSQV");

    const [emailState, setEmailState] = useState(sessionStorage.getItem("gmos-email-state") || "closed");
    const [email, setEmail] = useState();

    function toFighters() {
        Sound.startGame().play(null, function () {
            navigate("/fighters");
            isMobile && window.scrollTo(0, 1);
        })
    }

    function leaveEmail() {
        airtable('UserEmails').create([{
                "fields": {"Email": email}
            }], function(err, records) {
            if (err) {
                console.error(err);
            } else {
                setEmailState("sent");
                sessionStorage.setItem("gmos-email-state", "sent");
            }
        });

        setEmailState("sent");
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">
            <main className="App-body">
                <div className="fighters">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="selection-header">
                                {`${winner.toUpperCase()} WINS: ${year}`}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-around" spacing={2} className="fighters-grid">
                            <Fighter id={fighter.id} weapon={fighter.weapons[0]} year={year} winner={winner} />
                            <Fighter id={vsFighter.id} weapon={vsFighter.weapons[0]} year={year} winner={winner} />
                        </Grid>

                    </Box>
                </div>


                {winner &&
                    <div className="fighters">
                        <Box sx={{flexGrow: 1}}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={12}>
                                    <div>&nbsp;</div>
                                    <div>
                                        If you like the game please share it with friends or on social media.
                                    </div>
                                    <div className="fighter-weapons">
                                        <div className="share-social">
                                            <ShareSocial
                                                url={shareUrl}
                                                socialTypes={['facebook','twitter', 'reddit', 'email']}
                                            />
                                        </div>
                                    </div>

                                    {emailState !== "sent" &&
                                        <div>
                                            The game is just a BETA. We have tons of things to add so if you enjoy the
                                            idea, signup for updates to:
                                            <ul>
                                                <li>Trade with real money</li>
                                                <li>Get access to new characters</li>
                                                <li>Influence the upcoming features</li>
                                            </ul>
                                        </div>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    {emailState === "opened" &&
                                        <div>
                                            <input type="email" name="email" onChange={e => setEmail(e.target.value)}/>
                                            <button onClick={e => leaveEmail()} className="fighter-weapon">
                                                SEND
                                            </button>
                                            <a href="#/fighters" onClick={e => toFighters()} className="fighter-weapon">
                                                NEW GAME >
                                            </a>
                                        </div>
                                    }
                                    {emailState === "closed" &&
                                        <div>
                                            <div className="fighter-weapons">
                                                <VolumeButton/>
                                                <button onClick={e => setEmailState("opened")} className="fighter-weapon">
                                                    SIGNUP
                                                </button>
                                                <button onClick={e => toFighters()} className="fighter-weapon">
                                                    NEW GAME >
                                                </button>
                                            </div>
                                            <div>
                                                All the fights are based on actual strategies backtested by Breaking Equity. Not a financial advice.
                                            </div>
                                        </div>
                                    }

                                    {emailState === "sent" &&
                                        <div>
                                            <div>
                                                Thank you for your interest in the game and signing up for the updates.
                                            </div>
                                            <div>
                                                <button onClick={e => toFighters()} className="fighter-weapon">
                                                    NEW GAME >
                                                </button>
                                            </div>
                                            <div>
                                                All the fights are based on actual strategies backtested by Breaking Equity. Not a financial advice.
                                            </div>
                                        </div>
                                    }
                                </Grid>
                            </Grid>


                        </Box>
                    </div>
                }
            </main>
        </div>
    );
}

export default Result;
