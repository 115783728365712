import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState} from "react";
import {fighters} from "./data"
import {useNavigate} from "react-router-dom";
import {Sound} from "./sound2";
import {randomOther} from "./utils";
import VolumeButton from "./VolumeButton";
import {useEffect} from "react";
import ReactGA from "react-ga";

function SelectFighters() {

    const navigate = useNavigate();

    const [fighter, setFighter] = useState(fighters[0]);
    const [vsFighter, setVsFighter] = useState();

    function toArenas() {
        const vs = randomOther(fighter, fighters);
        setVsFighter(vs);
        Sound.versus(vs.id).then(() => {
            navigate("/arenas?f=" + fighter.id + "&vs=" + vs.id);
        })
    }

    function chooseFighter(e) {
        const id = e.target.id;
        const selectedFighter = fighters.filter((fighter, index) => fighter.id === id)[0];
        setFighter(selectedFighter);

        Sound.fighter(id).play();
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">
            <main className="App-body">
                <div className="fighters-vs">
                    <div id={fighter.id + "-pointing-lr"}></div>
                    <div id={vsFighter?.id + "-pointing-rl"}></div>
                </div>
                <div className="fighters">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="selection-header">
                                SELECT YOUR FIGHTER
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="fighters-grid" justifyContent="center">
                            {fighters.map((f, index) =>
                                <Grid item xs={6} md={3} sm={2} className={fighter.id === f.id ? "fighter-active" : (vsFighter && vsFighter?.id === f.id ? "fighter-vs" : null)}>
                                    <div className="fighter-wrapper">
                                        <div id={f.id} onClick={chooseFighter}></div>
                                    </div>
                                    <div>{f.name}</div>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} className="fighter-bio">
                                {fighter?.bio}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} cl>
                            <Grid item xs={12} md={12} className="fighter-weapons">
                                <div className="fighter-weapons">
                                    FIGHTER'S WEAPON(S): &nbsp;
                                    {fighter.weapons.map((w, index) =>
                                            <span>{w}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className="fighter-weapons">
                                    <VolumeButton/>
                                    <a onClick={e => toArenas()} href="#/fighters" className="fighter-weapon">
                                        SELECT ARENA >
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </main>
        </div>
    );
}

export default SelectFighters;
