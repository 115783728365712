import './App.css';
import FeatherIcon from 'feather-icons-react';
import {useState} from "react";


function VolumeButton() {
    const v = localStorage.getItem("gmos-volume");
    if(!v) {
        localStorage.setItem("gmos-volume", 1);
    }

    const [volume, setVolume] = useState(localStorage.getItem("gmos-volume"));

    function toggleVolume() {
        const v = Number(!volume);
        localStorage.setItem("gmos-volume", v);
        setVolume(v);
    }

    return (
        <button onClick={e => toggleVolume()} className="fighter-weapon">
            <FeatherIcon icon={volume > 0 ? "volume-2" : "volume-x"} />
        </button>
    );
}

export default VolumeButton;
