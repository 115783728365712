export class Sound {
    constructor(path, callback) {
        this.path = path;
        // this.callback = callback;
    }

    then(callback) {
        return new Sound(this.path).play(callback, null);
    }

    play(onended, onplay) {
        window.audio && window.audio.pause();

        window.audio = this._getAudio(this.path);
        window.audio.onended = function () {
            onended();
        }
        window.audio.onplay = function () {
            onplay();
        }
        window.audio.play();

        return this;
    }

    _getVolume() {
        return localStorage.getItem("gmos-volume");
    }

    _getAudio = (path) => {
        const audio = new Audio(process.env.PUBLIC_URL + "/audio/" + path);
        audio.volume = this._getVolume();

        return audio;
    }

    // specific sounds
    static menu() {
        return new Sound("menu.mp3");
    }

    static startGame() {
        return new Sound("misc/start-game.wav");
    }

    static roundOne() {
        return new Sound("misc/round1-fight.mp3");
    }

    static fighter(id) {
        return new Sound("fighters/" + id + "/" + id + ".mp3");
    }

    static versus(id) {
        return new Sound("fighters/" + id + "/versus-" + id + ".mp3");
    }

    static fatality(id) {
        let random = Math.floor(Math.random() * 3) + 1;
        return new Sound("fighters/" + id + "/win0" + random + ".mp3");

    }

    static randomWisdom() {
        let randomWisdom = Math.floor(Math.random() * 13) + 1;
        while (window.randomWisdom === randomWisdom) {
            randomWisdom = Math.floor(Math.random() * 13) + 1;
        }
        window.randomWisdom = randomWisdom;
        return new Sound("wisdom/0" + randomWisdom + ".wav");
    }

    static winner(id) {
        return new Sound("misc/" + id + "wins.mp3");
    }

}