import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState, useEffect} from "react";
import {arenas} from "./data"
import {useNavigate, useSearchParams} from "react-router-dom";
import {Sound} from "./sound2";
import {isMobile} from "react-device-detect";
import VolumeButton from "./VolumeButton";
import ReactGA from "react-ga";

function SelectArena() {

    const navigate = useNavigate();

    const [arena, setArena] = useState(arenas[0]);
    const [searchParams, setSearchParams] = useSearchParams();
    const fighter = searchParams.get("f");
    const vs = searchParams.get("vs");

    // =====================
    // navigations functions
    function toFighters() {
        Sound.startGame().play(null, function (){
            navigate("/fighters");
            isMobile && window.scrollTo(0, 1);
        });
    }

    function toFight() {
        Sound.menu().then(() => {
            navigate(`/fight?r=1&y=${arena.year}&f=${fighter}&vs=${vs}`);
            Sound.roundOne().play();
        })
    }

    function chooseArena(e) {
        const id = e.target.id;
        const selectedArena = arenas.filter((fighter, index) => fighter.id === id)[0];
        setArena(selectedArena);
        Sound.randomWisdom().play();
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">

            <main className="App-body">
                <div className="fighters">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="selection-header">
                                SELECT ARENA
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="fighters-grid" justifyContent="center">
                            {arenas.map((a, index) =>
                                <Grid item xs={6} md={3} sm={2} className={arena && arena.id === a.id ? "fighter-active" : null}>
                                    <div className="fighter-wrapper">
                                        <div id={a.id} onClick={chooseArena}></div>
                                    </div>
                                    <div>{a.name}</div>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} className="fighter-bio">
                                {arena?.bio}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} cl>
                            <Grid item xs={12} md={12}>
                                <div className="fighter-weapons">
                                    <VolumeButton/>
                                    <button onClick={e => toFighters()} className="fighter-weapon">
                                        &lt; FIGHTERS
                                    </button>
                                    <button onClick={e => toFight()} className="fighter-weapon">
                                        FIGHT >
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </main>
        </div>
    );
}

export default SelectArena;
