import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {fighters, quarterlyResults, ROUND_DURATION} from "./data";
import Fighter from "./Fighter";
import {useNavigate} from "react-router-dom";
import {Sound} from "./sound2";
import VolumeButton from "./VolumeButton";
import ReactGA from "react-ga";

function Fight() {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [round, setRound] = useState(0);
    const [winner, setWinner] = useState(null);

    const year = searchParams.get("y");
    const f = searchParams.get("f");
    const vs = searchParams.get("vs");
    const fighter = fighters.filter((fighter, index) => fighter.id === f)[0];
    const vsFighter = fighters.filter((fighter, index) => fighter.id === vs)[0];
    const w = searchParams.get("w") || fighter.weapons[0];

    const numRounds = Object.keys(quarterlyResults[fighter.id][year]).length;

    function countdown(r, max) {
        setRound(r);
        if(r <= max){
            setTimeout(function () {
                countdown(r +1, max);
            }, ROUND_DURATION);
        } else if (r > max) {
            const fResults = Object.values(quarterlyResults[fighter.id][year]);
            const vsResults = Object.values(quarterlyResults[vsFighter.id][year]);

            const fValue = fResults[fResults.length - 1];
            const vsValue = vsResults[vsResults.length - 1];

            const winner = fValue > vsValue ? fighter.id : vsFighter.id;
            setWinner(winner);
        }
    }

    function finishThem() {
        Sound.fatality(winner).play(null, function (){
            navigate(`/result?r=1&y=${year}&f=${f}&vs=${vs}&w=${winner}`);
        });
    }

    // execute rounds
    useEffect(()=>{
        if(round === 1) {
            setWinner(null);
        }
        countdown(1, numRounds);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])


    return (
        <div className="App">
            <main className="App-body">
                <div className="explosion"></div>
                <div className="fighters">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="selection-header">
                                {winner ? `${winner.toUpperCase()} WINS: ${year}` : `ROUND ${round || 1}: Q${round || 1} ${year}`}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-around" spacing={2} className="fighters-grid">

                            <Fighter id={fighter.id} year={year} round={round}/>
                            <Fighter id={vsFighter.id} year={year} round={round}/>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} className="fighter-bio">
                                <div className="fighter-weapons">
                                    <VolumeButton/>
                                    {winner &&
                                        <button onClick={e => finishThem()}
                                           className={winner ? "fighter-weapon fighter-weapon-blink" : "fighter-weapon"}>
                                            FINISH THEM >
                                        </button>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} className="fighter-bio">
                                Every fighter starts with $10,000 portfolio and uses their weapons to invest or
                                trade in the selected environment. All the fights are based on actual strategies and real backtests.
                            </Grid>
                        </Grid>


                    </Box>
                </div>

            </main>
        </div>
    );
}

export default Fight;
