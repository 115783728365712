import './App.css';
import Grid from '@mui/material/Grid';
import {useState, useEffect} from "react";
import CountUp from "react-countup";
import {fighters, INITIAL_CAPITAL, quarterlyResults, ROUND_DURATION} from "./data";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    animate   : true,
    elements: {
        line: {
            tension: 0.5
        }
    },
    animation: {
        duration: ROUND_DURATION,
    },
    animations: {
        tension: {
            duration: ROUND_DURATION,
            easing: 'linear',
            from: 1,
            to: 0.2,
            loop: true
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            },
        },
        y: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            },
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false
        },
    },
};

function Fighter(props) {

    let {id, weapon, year, round, winner, ...rest} = props;

    const fighter = fighters.filter((f, i) => f.id === id)[0];
    const results = quarterlyResults[fighter.id][year];

    if(!round) {
        round = Object.keys(results).length;
    }
    if(!weapon) {
        weapon = fighter.weapons[0];
    }

    const labels = [" "].concat(Object.keys(results)).filter((l, i) => i <= round);
    const items = [INITIAL_CAPITAL].concat(Object.values(results)).filter((l, i) => i <= round);


    const data = {
        //label for initial capital value
        labels: labels,
        datasets: [
            {
                //add initial capital as a starting value
                data: items,
                backgroundColor: 'black',
                borderColor: '#c1c1c1',
                borderWidth: 5,
                lineTension: 1
            }
        ]
    };

    //portfolio values
    const [pStart, setPStart] = useState(INITIAL_CAPITAL);
    const [pEnd, setPEnd] = useState(INITIAL_CAPITAL);
    const [fighterWins, setFighterWins] = useState([]);

    function fightRound() {
        // if(r <= maxR) {
            const start = round > 1 ? results["q" + String(round - 1)] : INITIAL_CAPITAL;
            const end = results["q" + String(round)];

            setPStart(start);
            setPEnd(end);

            if(start < end) {
                fighterWins.push(1);
                setFighterWins(fighterWins);
            }
        // }
    }

    // execute rounds
    useEffect(()=>{
        fightRound();
        if( round === 1) {
            setFighterWins([]);
        }
    }, [round])


    return (
            <Grid item xs={6} md={3} sm={2}>
                <div className={winner ? "fighter-card fighter-card-short" : "fighter-card"}>
                    <div className="fighter-wrapper">
                        <div id={fighter.id} className={winner === fighter.id ? "wins" : winner && "loses"}>
                            <div className="fighter-stats">
                                <span className="fighter-wins">
                                    {fighterWins.map((value, index) =>
                                        <span className="fighter-win">
                                            *
                                        </span>
                                    )}
                                </span>
                                <span className="fighter-value">
                                    <CountUp start={pStart} end={pEnd}
                                             className={winner === fighter.id ? "green" : "black"}
                                             duration={(ROUND_DURATION / 1000) - 1}
                                             formattingFn={v => v.toLocaleString('en-US', {
                                                 style: 'currency',
                                                 maximumFractionDigits: 0,
                                                 minimumFractionDigits: 0,
                                                 currency: 'USD',
                                             })}
                                        // onEnd={countWins}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>{fighter.name}&nbsp;</div>

                    {!winner &&
                        <div>
                            <Line options={options} data={data} />
                        </div>
                    }

                </div>
                {!winner && <div>{weapon}</div> }
            </Grid>

    );
}

export default Fighter;
