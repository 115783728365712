export const fighters = [
    {
        name: "WSB",
        id: "wsb",
        since: 2020,
        weapons: ["Reddit Sentiment"],
        bio: "WallStreetBets is a subreddit to discuss stock and option trading. It has become popular for profane jargon, aggressive strategies, GameStop short squeeze."
    },
    {
        name: "Jim Cramer",
        id: "cramer",
        since: 2016,
        weapons: ["Mad Money Daily Picks"],
        bio: "Jim Cramer is an American television personality and author, the host of Mad Money on CNBC. He was a hedge fund founder and manager in the past."
    },
    {
        name: "Warren Buffett",
        id: "buffett",
        since: 2007,
        weapons: ["SP 500 and Bonds"],
        bio: "Warren Buffett is an American business magnate and investor, the CEO of Berkshire Hathaway. He is one of the most successful investors with a net worth of $116b+"
    },
    {
        name: "Cathie Wood",
        id: "wood",
        since: 2018,
        weapons: ["ARKK ETF"],
        bio: "Catherine Wood is an American investor and the founder of an investment management firm Ark Invest. Rise and fall of ARKK ETF made her popular."
    },
    // {
    //     name: "Nancy Pelosi",
    //     id: "pelosi",
    //     since: 2018,
    //     weapons: ["Congress Trading"],
    //     bio: "Nancy Pelosi is an American politician serving as speaker of the United States House of Representatives. Her stock trades attract a lot of retail investors' attention and copy traders."
    // }
]

export const arenas = [
    // {
    //     name: "Market Crash",
    //     id: "housingcrash",
    //     year: 2008,
    //     bio: "The financial crisis of 2008, or Global Financial Crisis, was a severe worldwide economic crisis that occurred in the early 21st century. It was the most serious financial crisis since the Great Depression."
    // },
    {
        name: "Market Crash",
        id: "housingcrash",
        year: 2018,
        bio: "December 2018 Market fell more than 9% as investors feared a tightening monetary policy, slowing economy, and a trade war with China. It was the worst December since 1931."
    },
    {
        name: "Global Pandemic",
        id: "pandemic",
        year: 2020,
        bio: "On 20 February 2020, stock markets across the world suddenly crashed after growing instability due to the COVID-19 pandemic. It ended on 7 April 2020."
    },
    {
        name: "Post Pandemic",
        id: "postpandemic",
        year: 2021,
        weapons: ["Index Investing", "Berkshire Hathaway"],
        bio: "Market did great in 2021, led by U.S. large-cap stocks, which returned nearly 29% for the year. This performance comes on the back of strong years in both 2019 and 2020."
    },
    {
        name: "New World",
        id: "newworld",
        year: 2022,
        bio: "Bear market started in 2022. The average length of a bear market is about 9.6 months. Would 2022 be a year of hope or a year of doom only the time will show."
    }
]

export const quarterlyResults = {
    "cramer" : {
        2018: {"q1" : 10000 - 226, "q2" : 10000 - 226 + 683, "q3" : 10000 - 226 + 683 + 1664, "q4" : 10000 - 226 + 683 + 1664 + 580},
        2020: {"q1" : 10000 + 1919, "q2" : 10000 + 1919 + 1979, "q3" : 10000 + 1919 + 1979 - 547, "q4" : 10000 + 1919 + 1979 - 547 - 389},
        2021: {"q1" : 10000 + 2641, "q2" : 10000 + 2641 + 2033, "q3" : 10000 + 2641 + 2033 + 1267, "q4" : 10000 + 2641 + 2033 + 1267 + 882},
        2022: {"q1" : 10000 + 1112, "q2" : 10000 + 1112 + 611},
    },
    "wsb": {
        2018: {"q1": 10000, "q2" : 10000, "q3": 10000, "q4": 7986.14},
        2019: {"q1": 10060.68, "q2": 10050.89, "q3": 10044.82, "q4": 11754.91},
        2020: {"q1": 7264.44, "q2": 14232.89, "q3": 15994.86, "q4": 16649.43},
        2021: {"q1": 18751.92, "q2": 17807.33, "q3": 15449.78, "q4": 15684.44},
        2022: {"q1": 14319.77, "q2": 13739.93}
    },
    "pelosi" : {
        2018: {"q1" : 10000, "q2" : 10000, "q3" : 10000 + 46, "q4" : 10000 + 46 - 1826},
        2020: {"q1" : 10000 - 957, "q2" : 10000 - 957 + 1369, "q3" : 10000 - 957 + 1369 + 951, "q4" : 10000 - 957 + 1369 + 951 + 126},
        2021: {"q1" : 10000 + 848, "q2" : 10000 + 848 + 0, "q3" : 10000 + 848 + 0 + 716, "q4" : 10000 + 848 + 0 + 716 + 0},
        2022: {"q1" : 10000, "q2" : 10000},
    },
    "wood" : {
        2018: {"q1" : 10000 + 464, "q2" : 10000 + 464 + 1692, "q3" : 10000 + 464 + 1692 + 683, "q4" : 10000 + 464 + 1692 + 683 - 3000},
        2020: {"q1" : 10000 - 1299, "q2" : 10000 - 1299 + 6011 , "q3" : 10000 - 1299 + 6011 + 1891, "q4" : 10000 - 1299 + 6011 + 1891 + 5473},
        2021: {"q1" : 10000 - 483, "q2" : 10000 - 483 + 608 , "q3" : 10000 - 483 + 608 - 1555, "q4" : 10000 - 483 + 608 - 1555 - 1270},
        2022: {"q1" : 10000 - 3082, "q2" : 10000 - 3082 - 2484},
    },
    "buffett" : {
        2018: {"q1" : 10000 - 152, "q2" : 10000 - 152 + 300, "q3" : 10000 - 152 + 300 + 682, "q4" : 10000 - 152 + 300 + 682 - 1478},
        2020: {"q1" : 10000 - 1708, "q2" : 10000 - 1708 + 1776, "q3" : 10000 - 1708 + 1776 + 773, "q4" : 10000 - 1708 + 1776 + 773 + 1005},
        2021: {"q1" : 10000 + 446, "q2" : 10000 + 446 + 706, "q3" : 10000 + 446 + 706 + 20, "q4" : 10000 + 446 + 706 + 20 + 1007},
        2022: {"q1" : 10000 - 479, "q2" : 10000 - 479 - 808},
    },
    "spy" : {
        2018: {"q1" : 230, "q2" : 104, "q3" : 156, "q4" : 949},
        2020: {"q1" : 1230, "q2" : -104, "q3" : 756, "q4" : 549},
        2021: {"q1" : 1230, "q2" : -104, "q3" : 756, "q4" : 549},
        2022: {"q1" : 1230, "q2" : -104},
    },

}

export const ROUND_DURATION = 2000;
export const INITIAL_CAPITAL = 10000;